import * as qs from 'qs';

export function bind(node: HTMLElement, action: string, method: (e: Event) => void): void {
  node.addEventListener(action, method)
}

export function unbind(node: HTMLElement, action: string, method: (e: Event) => void): void {
  node.removeEventListener(action, method)
}

export function getCurrentQueryString() {
  if (window.location.search) {
    return qs.parse(window.location.search.replace('?', ''));
  }
  return {};
}

export function pushToQuery(e: Event) {
  const node = e.currentTarget as HTMLInputElement | HTMLSelectElement;
  const key = node.getAttribute('name');
  const value = node.value;
  const queryString = getCurrentQueryString();
  queryString[key] = value;

  window.history.replaceState(null, null, `${window.location.pathname}?${qs.stringify(queryString)}`)
}
