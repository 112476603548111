
const beforeSubmit = (captcha: Element) => {
  captcha.classList.remove('rounded', 'border-2', 'border-red-500');
}

export default function init() {
  const captchas = document.querySelectorAll('.g-recaptcha');
  captchas.forEach((captcha, index) => {
    let valid = false;
    (window as any).grecaptcha.render(captcha, {
      sitekey: captcha.getAttribute('data-sitekey'),
      theme: captcha.getAttribute('data-theme'),
      callback: () => {
        const response = (window as any).grecaptcha.getResponse(index);
        if (response) {
          valid = true;
        }
      }
    });

    const btn = document.getElementById(captcha.getAttribute('data-btn'));
    const form = document.getElementById(captcha.getAttribute('data-form'));
    const isHtmx = captcha.getAttribute('data-htmx') === "true";

    btn.addEventListener('click', function (event) {
      event.preventDefault();
      beforeSubmit(captcha);

      if (!valid) {
        captcha.classList.add('border-2', 'rounded', 'border-red-500');
      } else {
        console.log('response', valid);
        if (isHtmx) {
          (window as any).htmx.trigger(form, 'submit');
        } else {
          (form as HTMLFormElement).submit();
        }
      }
    })
  });
}
