import Carousels from './modules/carousels';
import InputToQuery from './modules/inputToQuery';
import Dropdowns from './modules/dropdown';
import Captcha from './modules/captcha';

const App = (): void => {
  console.log('App Init');
  Carousels();
  InputToQuery();
  Dropdowns();

  (window as any).onCaptchaLoaded = Captcha;
}

document.addEventListener('DOMContentLoaded', () => {
  App();
});
