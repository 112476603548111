import easydropdown from 'easydropdown';
import EasydropdownFacade from 'easydropdown/dist/Easydropdown/EasydropdownFacade';
import { Context } from '../types';

class Dropdowns {
  private dropdowns: EasydropdownFacade[] = [];

  constructor() {
    this.onLoad(document);

    document.addEventListener('htmx:afterSwap', ({ detail }: any) => {
      this.afterSwap(detail.target);
    })

    document.addEventListener('htmx:beforeSwap', ({ detail }: any) => {
      this.beforeSwap(detail.target);
    })
  }

  getNodes(context: Context) {
    const inputs = context.querySelectorAll('[data-dropdown]');

    return {
      inputs
    }
  }

  /**
   * LifeCycle Method
   * @param context
   */
  onLoad(context: Context) {
    const { inputs } = this.getNodes(context);
    if (!inputs) {
      return;
    }
    this.dropdowns = Array.from(inputs).map((node: HTMLSelectElement) => easydropdown(node));
  }

  /**
   * LifeCycle Method
   * @param context
   */
  afterSwap(context: Context) {
    const { inputs } = this.getNodes(context);
    if (!inputs) {
      return;
    }
    this.dropdowns = Array.from(inputs).map((node: HTMLSelectElement) => easydropdown(node));
  }

  /**
   * LifeCycle Method
   * @param context
   */
  beforeSwap(context: Context) {
    this.dropdowns.map((dd: EasydropdownFacade) => dd.destroy());
  }
}

export default function init() {
  new Dropdowns();
}
