import * as qs from 'qs';
import { bind, unbind, pushToQuery } from '../utils';
import { Context } from '../types';

class InputToQuery {
  constructor() {
    this.onLoad(document);

    document.addEventListener('htmx:afterSwap', ({ detail }: any) => {
      this.afterSwap(detail.target);
    })

    document.addEventListener('htmx:beforeSwap', ({ detail }: any) => {
      this.beforeSwap(detail.target);
    })
  }

  getNodes(context: Context) {
    const wrapper = context.querySelector('[data-input-to-query]');
    const inputs = wrapper.querySelectorAll('input, select');

    return {
      wrapper,
      inputs
    }
  }

  /**
   * LifeCycle Method
   * @param context
   */
  onLoad(context: Context) {
    const { inputs } = this.getNodes(context);
    inputs.forEach((node: HTMLElement) => {
      bind(node, 'change', pushToQuery);
    });
  }

  /**
   * LifeCycle Method
   * @param context
   */
  afterSwap(context: Context) {
    const { inputs } = this.getNodes(context);
    inputs.forEach((node: HTMLElement) => {
      bind(node, 'change', pushToQuery);
    });
  }

  /**
   * LifeCycle Method
   * @param context
   */
  beforeSwap(context: Context) {
    const { inputs } = this.getNodes(context);
    inputs.forEach((node: HTMLElement) => {
      unbind(node, 'change', pushToQuery);
    });
  }
}

export default function init() {
  if (document.querySelector('[data-input-to-query]')) {
    new InputToQuery();
  }
}
