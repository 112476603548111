import Swiper, { SwiperOptions } from 'swiper';
import 'swiper/swiper-bundle.css';

const defaultConfig: SwiperOptions = {
  spaceBetween: 40,
  slidesPerView: 3,
};

function fadeTransition(node: HTMLElement, newValue: string, delay: number) {
  node.classList.toggle('text-opacity-0');

  setTimeout(() => {
    node.innerHTML = newValue;
    node.classList.toggle('text-opacity-0');
  }, delay);
}

const createSwiper = (node: HTMLDivElement) => {
  const config = JSON.parse(node.getAttribute('data-swiper') || '{}');
  const container: HTMLDivElement = node.querySelector('.swiper-container');
  const type = node.getAttribute('data-swiper-type');
  const slides = node.querySelectorAll('.swiper-slide');

  if (type === 'company') {
    // add on change events
    const location: HTMLElement = node.querySelector('[data-swiper-location]');
    const year: HTMLElement = node.querySelector('[data-swiper-year]');
    const lead: HTMLElement = node.querySelector('[data-swiper-lead]');
    const ceo: HTMLElement = node.querySelector('[data-swiper-ceo]');

    const updateCompanyDetails = (swiper: Swiper) => {
      const activeSlide = slides.item(swiper.realIndex);
      if (!activeSlide) {
        return;
      }
      const data = JSON.parse(activeSlide.getAttribute('data-company') || '{}');

      fadeTransition(location, data.location, 300);
      fadeTransition(year, data.year, 400);
      fadeTransition(ceo, data.ceo, 500);
      fadeTransition(lead, data.lead, 500);
    }

    config.on = {
      slideChange: (swiper: Swiper) => {
        updateCompanyDetails(swiper);
      }
    }
  }

  // if (type === 'lp') {
  //   config.on = {
  //     onSlideChangeEnd: (swiper: Swiper) => {
  //       if (swiper.isEnd) {
  //         swiper.slideTo(0);
  //       }
  //     }
  //   }
  // }

  const instance = new Swiper(container, {
    ...defaultConfig,
    ...config
  });

  const prevBtn = node.querySelector('.swiper-prev');
  const nextBtn = node.querySelector('.swiper-next');
  if (prevBtn) {
    prevBtn.addEventListener('click', () => instance.slidePrev());
  }
  if (nextBtn) {
    nextBtn.addEventListener('click', () => instance.slideNext());
  }

  // if (type === 'lp') {
  //   // progress
  //   const STATE: 'progress' | 'pause' = 'progress';
  //   const slideTime = 3000;
  //   let progress = 0;
  //   let intervalRef: NodeJS.Timeout = undefined;

  //   const ring: SVGElement = node.querySelector('.progress-ring');
  //   const circle: SVGCircleElement = ring.querySelector('.progress-ring__circle');
  //   const radius = circle.r.baseVal.value;
  //   const circumference = radius * 2 * Math.PI;
  //   circle.style.strokeDasharray = `${circumference} ${circumference}`;
  //   // circle.style.strokeDashoffset = `${circumference}`;

  //   const updateProgress = (percent: number) => {
  //     const offset = circumference - percent / 100 * circumference;
  //     circle.style.strokeDashoffset = `${offset}`;
  //   }

  //   const showProgress = () => {
  //     intervalRef = setInterval(() => {
  //       progress = progress + 100;
  //       const p = progress / slideTime * 100;
  //       updateProgress(p);

  //       if (p === 100 || p === 200) {
  //         if (instance.isEnd) {
  //           instance.slideTo(0);
  //         } else {
  //           instance.slideNext();
  //         }
  //       }

  //       if (p === 200) {
  //         progress = 0;
  //       }
  //     }, 100)
  //   }

  //   const pauseProgress = () => {
  //     clearInterval(intervalRef);
  //     intervalRef = undefined;
  //   }

  //   const stateChange = (state: string) => {
  //     switch (state) {
  //       case 'progress':
  //         showProgress();
  //         break;
  //       case 'pause':
  //         pauseProgress();
  //         break;
  //     }
  //   }

  //   // listen to hover events
  //   node.addEventListener('mouseover', () => {
  //     stateChange('pause');
  //   });

  //   node.addEventListener('mouseout', () => {
  //     stateChange('progress');
  //   });

  //   stateChange('progress');
  // }
}

export default function init() {
  const swipers = document.querySelectorAll('[data-swiper]');
  const swiperArray = Array.prototype.slice.call(swipers);
  swiperArray.forEach((node: HTMLDivElement) => {
    createSwiper(node);
  })
}
